<template>
  <section
    class="page-banner-section"
    :style="getImage(bannerImage)"
  >
    <h4>{{ title }}</h4>
  </section>
</template>

<script>
import bannerImage from '@/assets/images/bg/banner2.jpeg'

export default {
  props: {
    title: { type: String, default: '' },
  },
  data() {
    return {
      bannerImage,
    }
  },
  methods: {
    getImage(image) {
      return `background: url(${image})`
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
