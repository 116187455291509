<template>
  <div>
    <ServicesTopBar />
    <Banner title="Contact Us" />
    <section class="page_detail_section">
      <div class="container">
        <b-card class="details-page-box">
          <div class="row p-2">
            <div class="col-md-6">
              <validation-observer ref="contactForm">
                <b-form
                  id="my-form"
                  @submit.prevent="contactUs"
                >
                  <b-row>

                    <!-- first name -->
                    <b-col cols="6">
                      <b-form-group
                        label="First Name"
                        label-for="v-first-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="firstname"
                          rules="required"
                        >
                          <b-form-input
                            id="v-first-name"
                            v-model="contact.first_name"
                            placeholder="First Name"
                            :state="errors.length > 0 ? false:null"
                            size="lg"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- first name -->
                    <b-col cols="6">
                      <b-form-group
                        label="Last Name"
                        label-for="v-last-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="lastname"
                          rules="required"
                        >
                          <b-form-input
                            id="v-last-name"
                            v-model="contact.last_name"
                            placeholder="Last Name"
                            :state="errors.length > 0 ? false:null"
                            size="lg"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- email -->
                    <b-col cols="6">
                      <b-form-group
                        label="Email"
                        label-for="v-email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="v-email"
                            v-model="contact.email"
                            type="email"
                            placeholder="Email"
                            :state="errors.length > 0 ? false:null"
                            size="lg"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- mobile -->
                    <b-col cols="6">
                      <b-form-group
                        label="Phone"
                        label-for="v-phone"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="phone"
                          rules="required"
                        >
                          <b-form-input
                            id="v-phone"
                            v-model="contact.phone"
                            type="text"
                            placeholder="Phone"
                            :state="errors.length > 0 ? false:null"
                            size="lg"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- subject -->
                    <b-col cols="12">
                      <b-form-group
                        label="Subject"
                        label-for="v-subject"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="subject"
                          rules="required"
                        >
                          <b-form-input
                            id="v-subject"
                            v-model="contact.subject"
                            type="text"
                            placeholder="Subject"
                            :state="errors.length > 0 ? false:null"
                            size="lg"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- subject -->
                    <b-col cols="12">
                      <b-form-group
                        label="Message"
                        label-for="v-message"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="message"
                          rules="required"
                        >
                          <b-form-textarea
                            id="v-message"
                            v-model="contact.message"
                            placeholder="Message"
                            :state="errors.length > 0 ? false:null"
                            rows="6"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- checkbox -->
                    <!-- submit and reset -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="outline-primary"
                        class="mr-1"
                        size="lg"
                      >
                        Submit
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <div class="col-md-6">
              <h2>Get In Touch</h2>
              <p class="mb-3">
                Do you have any issue..? Talk with our support team.
              </p>

              <b-media
                class="mb-3"
                vertical-align="top"
              >
                <template #aside>
                  <feather-icon
                    icon="MapPinIcon"
                    size="50"
                    class="text-success"
                  />
                </template>
                <h4>
                  Reach Us
                </h4>
                <b-card-text>
                  {{ contacts && contacts.address }}
                  <br> {{ contacts && contacts.city }}, {{ contacts && contacts.state }} {{ contacts && contacts.zip_code }}
                </b-card-text>
              </b-media>

              <b-media
                class="mb-3"
                vertical-align="top"
              >
                <template #aside>
                  <feather-icon
                    icon="MailIcon"
                    size="50"
                    class="text-success"
                  />
                </template>
                <h4>
                  Email
                </h4>
                <b-card-text>
                  <p>{{ contacts && contacts.email }}</p>
                </b-card-text>
              </b-media>

              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="PhoneIcon"
                    size="50"
                    class="text-success"
                  />
                </template>
                <h4>
                  Call Us
                </h4>
                <b-card-text>
                  {{ contacts && contacts.phone }}
                </b-card-text>
              </b-media>
            </div>
          </div>
        </b-card>
      </div>
    </section>
    <SiteFooter />
  </div>
</template>

<script>
import {
  BCard, BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BMedia,
  BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import Banner from '../components/page/Banner.vue'
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: {
    ServicesTopBar,
    SiteFooter,
    Banner,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardText,
    BForm,
    BButton,
    BMedia,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
    }
  },
  computed: {
    contacts() {
      return this.$store.state.page_content.contact_contents?.content
    },
  },
  mounted() {
    this.getContent()
  },
  methods: {
    getContent() {
      return this.$store.dispatch('page_content/getAllContactContent', 'contacts')
    },
    getImage(image) {
      return `background: url(${image})`
    },
    contactUs() {
      this.$refs.contactForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('contact/contactUs', this.contact)
          const form = document.getElementById('my-form')
          form.reset()
        }
      })
    },
    emptyData() {
      this.contact = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
      }
    },
  },
}
</script>

<style scoped>

</style>
